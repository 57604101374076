<template>
  <div>
    <header
      :class="{ minimized: showMinimized }"
      ref="header"
      class="desktop w-full h-20 px-10 py-4 mb-1 flex justify-between items-center fixed top-0 z-[100] transition duration-200 select-none"
    >
      <div class="flex gap-x-6 items-center">
        <Logo class="w-[104px] h-12"></Logo>
        <div v-show="!navStore.hideMenu" class="flex items-center">
          <template :key="key" v-for="(menu, key) in menuList">
            <template v-if="menu.children">
              <client-only>
                <tippy
                  :arrow="false"
                  :offset="[150, 0]"
                  interactive
                  class="flex"
                  trigger="mouseenter focus click"
                  placement="bottom-end"
                  :onShown="useHideTippyOnChildrenClick"
                >
                  <template #default="{ state }">
                    <ButtonsMenu
                      :is-selected="isCurrentRoute(menu.path)"
                      :disabled="menu.disabled"
                      class="py-2 md:py-4"
                    >
                      <div class="flex items-center gap-x-1">
                        {{ $t(menu.name) }}
                        <Icon
                          :class="state.isVisible && 'rotate-180'"
                          name="IconChevronDown"
                          size="24"
                          class="transition-transform duration-75"
                        ></Icon>
                      </div>
                    </ButtonsMenu>
                  </template>
                  <template #content>
                    <HeaderLiveTv></HeaderLiveTv>
                  </template>
                </tippy>
              </client-only>
            </template>
            <template v-else>
              <ButtonsMenu
                :is-selected="isCurrentRoute(menu.path)"
                :disabled="menu.disabled"
                :to="localePath(menu.path)"
                class="py-2 md:py-4"
              >
                {{ $t(menu.name) }}
              </ButtonsMenu>
            </template>
          </template>
        </div>
      </div>
      <div class="flex gap-x-6">
        <NuxtLink
          v-show="!navStore.hideMenu"
          :to="localePath(isKidsMode ? '/kids/search' : '/search')"
        >
          <ButtonsIcon
            icon-name="IconSearch"
            size="20"
            :class="{
              'bg-white/30': isCurrentRoute(
                isKidsMode ? '/kids/search' : '/search'
              ),
            }"
          ></ButtonsIcon>
        </NuxtLink>
        <template v-if="!navStore.hideMenu">
          <ButtonsCta
            v-if="!onKids"
            class="variant-glass"
            @click="navStore.onKidsClick"
          >
            {{ $t("MenuCartoon") }}
          </ButtonsCta>
          <ButtonsCta
            v-else
            class="variant-secondary"
            @click="navStore.onExitKidsClick"
          >
            {{ $t("MenuExitCartoon") }}
          </ButtonsCta>
        </template>
        <ButtonsProfile v-if="sessionStore.isAuthenticated"></ButtonsProfile>
        <ModalButtonsGetStarted v-else></ModalButtonsGetStarted>
        <client-only>
          <LanguageSwitcher v-if="!sessionStore.token"></LanguageSwitcher>
        </client-only>
      </div>
    </header>
  </div>
</template>

<script setup>
import { useNavStore } from "@/stores/nav";
import { useAuthStore } from "@/stores/auth";
import { useWindowScroll, useElementSize } from "@vueuse/core";
import { useSessionStore } from "@/stores/session";

const isKidsMode = useIsKidsMode();
const navStore = useNavStore();
const sessionStore = useSessionStore();

const { isXs } = useBreakpoints();
const route = useRoute();
const headerNav = useHeaderNav();
const localePath = useLocalePath();
const { y } = useWindowScroll();
const isOpenBoardingModal = ref(false);
const header = ref(null);
const showMinimized = ref(false);
const lastScrollPosition = ref(0);
const scrollDirection = ref(null);
const isFirstStep = ref(true);
const { height: headerHeight } = useElementSize(header); // Replace this with your actual header height in pixels
const authStore = useAuthStore();

const onKids = computed(() => route.path.includes("kids"));

const menuList = computed(() => {
  return headerNav.menuItems.value.filter((menu) => {
    if (menu.requiresAuth && !sessionStore.isAuthenticated) {
      return false;
    }

    const userType = route.path.includes("kids") ? "kid" : "adult";
    return (
      ["both"].includes(menu.device) && [userType, "both"].includes(menu.type)
    );
  });
});

const isCurrentRoute = (path) => {
  let currentPath = route.path;
  const isArabic = currentPath.startsWith("/ar");
  const isEnglish = currentPath.startsWith("/en");

  // Helper function to normalize paths
  const normalizePath = (p) => {
    if (isArabic && !p.startsWith("/ar")) {
      return `/ar${p}`;
    }
    if (isEnglish && !p.startsWith("/en")) {
      return `/en${p}`;
    }
    return p;
  };

  // Normalizing paths for comparison
  path = normalizePath(path);
  currentPath = normalizePath(currentPath);

  // Check for path without locales
  const { locale } = useCurrentLocale();
  let isSamePathWithoutLocale =
    path != `/${locale.value}/` && currentPath.includes(path);
  if (onKids) {
    isSamePathWithoutLocale =
      isSamePathWithoutLocale && path != `/${locale.value}/kids`;
  }

  // Special rules
  if (currentPath === "/ar/kids" && path === "/ar") return true;
  if (currentPath.includes("/sports") && path.includes("/sports")) return true;
  if (currentPath.includes("/radio") && path.includes("/radio")) return true;

  return (
    path === currentPath ||
    path === `${currentPath}/` ||
    isSamePathWithoutLocale
  );
};

/**
 * track the window's scrolling
 * to render different styles on the header
 */
watch(y, (currentPos, prevPos) => {
  scrollDirection.value = currentPos > prevPos ? "down" : "up";

  const twoFoldsScrolled = currentPos >= headerHeight.value * 2;
  // Perform actions based on the scroll direction and "two folds" condition
  if (scrollDirection.value === "down") {
    // Scroll down behavior
    // Example: Change header color after two folds scrolled
    if (twoFoldsScrolled) {
      showMinimized.value = true;
    }
  } else if (scrollDirection.value === "up" && currentPos <= twoFoldsScrolled) {
    // Scroll up behavior
    // Example: Reset header color when scrolling up
    // headerElement.style.backgroundColor = 'initial';
    showMinimized.value = false;
  }

  // Update the last known scroll position for the next scroll event
  lastScrollPosition.value = currentPos;
});

//TV Scan Code Login Enable Login Popup
watch(
  () => authStore.openSignInPopup,
  (val) => {
    if (!isXs && val) {
      isFirstStep.value = !val;
      isOpenBoardingModal.value = val;
      authStore.toggleSignInPopup(false);
    }
  },
  { immediate: true }
);
</script>

<script>
export default { name: "Header" };
</script>

<style lang="scss"></style>
